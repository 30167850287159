<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>简历管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem class="ivu-form-item-xl">
                    <Input v-model="filter.keywords" placeholder="ID，姓名，手机号码，工作经历，应聘职位，上传人" clearable/>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.gender" placeholder="请选择性别" clearable>
                        <Option value="男">男</Option>
                        <Option value="女">女</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.degree" placeholder="请选择学历" clearable>
                        <Option v-for="item in degreeArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <treeselect v-model="filter.intention"
                                :max-height="130"
                                :options="cityOpts"
                                :disable-branch-nodes="true"
                                placeholder="请选择意向城市"
                                loadingText="加载中..."
                                noOptionsText="暂无数据"
                                noResultsText="没有匹配数据">
                    </treeselect>
                </FormItem>
                <div class="group-select">
                    <FormItem>
                        <Select v-model="filter.salaryFrom" @on-change="setSalaryToMax" placeholder="请选择最低年薪" filterable clearable>
                            <Option v-for="item in salaryFromArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    至
                    <FormItem>
                        <Select v-model="filter.salaryTo" placeholder="请选择最高年薪" filterable clearable>
                            <Option v-for="item in salaryToArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                </div>
                <FormItem>
                    <deptTreeSelect v-model="filter.departmentId" :options="departmentOpts" placeholder="请选择所属组织" noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable />
                </FormItem>
                <FormItem>
                    <Select v-model="filter.label" placeholder="请选择简历状态" clearable>
                        <Option v-for="item in labelArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAdd" v-if="permissionButton.add">新增</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleImport" v-if="permissionButton.import">批量导入</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleBatchDel" :disabled="selectedMap.size === 0" v-if="permissionButton.batchDel">批量删除
                    </Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleBatchRecommend" :disabled="selectedMap.size === 0" v-if="permissionButton.batchRecommend">批量推荐</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleBatchMark" :disabled="selectedMap.size === 0" v-if="permissionButton.batchMark">批量标记</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleBatchExport" :disabled="selectedMap.size === 0">导出所选</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading" @on-select="handleSelect"
                       @on-select-cancel="handleCancel" @on-select-all="handleSelectAll" @on-select-all-cancel="handleAllCancel">
                    <template slot-scope="{ row, index }" slot="action">
                        <Dropdown transfer>
                            <a href="javascript:void(0)">操作<Icon type="ios-arrow-down"></Icon></a>
                            <DropdownMenu class="table-dropdown" slot="list">
                                <DropdownItem v-if="permissionButton.view" @click.native="handleView(row, index)">详情</DropdownItem>
                                <DropdownItem v-if="permissionButton.edit" @click.native="handleEdit(row, index)">编辑</DropdownItem>
                                <DropdownItem v-if="permissionButton.recommend" @click.native="handleRecommend(row, index)">推荐</DropdownItem>
                                <DropdownItem v-if="permissionButton.mark" @click.native="handleMark(row, index)">标记</DropdownItem>
                                <DropdownItem v-if="permissionButton.export" @click.native="handleExport(row, index)">导出</DropdownItem>
                                <DropdownItem v-if="permissionButton.preview && row.fileName && (row.fileName.substring(row.fileName.lastIndexOf('.')) === '.docx' || row.fileName.substring(row.fileName.lastIndexOf('.')) === '.pdf')" @click.native="handlePreView(row, index)">预览</DropdownItem>
                                <DropdownItem v-if="permissionButton.download && row.fileName" @click.native="handleDownload(row, index)">原件</DropdownItem>
                                <DropdownItem class="table-delete" v-if="permissionButton.del" @click.native="handleDel(row, index)">删除</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>

        <add-resume v-model="showAddModal" v-on:getResume="getResume"></add-resume>
        <edit-resume v-model="showEditModal" :id="id" v-on:getResume="getResume"></edit-resume>
        <mark-resume v-model="showMarkModal" :data="data" v-on:getResume="getResume"></mark-resume>
        <view-resume v-model="showViewModal" :id="id"></view-resume>
        <batch-recommend-resume v-model="showBatchRecommendModal" :data="selectedMap" v-on:handleUncheck="handleUncheck" v-on:getResume="getResume"></batch-recommend-resume>
        <batch-mark v-model="showBatchMarkModel" :data="selectedMap" v-on:handleUncheck="handleUncheck" v-on:getResume="getResume"></batch-mark>
        <recommend-resume v-model="showRecommendModal" :data="data" v-on:getResume="getResume"></recommend-resume>
        <importResume v-model="showImportModal" v-on:getResume="getResume"></importResume>
        <pre-view-document v-model="showPreViewModal" :data="data"></pre-view-document>
    </div>
</template>

<script>
import permission from '@/common/permission';
import {
    reqBatchExportResume,
    reqDeleteResume,
    reqExportResume, reqGetDepartmentTreeOpts,
    reqGetResume,
    reqResumeBatchDel
} from "../../api/admin-api";
import {downloadFile, reqGetDictionaryListByCode} from "../../api/system-api";
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import deptTreeSelect from "@riophae/vue-treeselect";
import city from '../../assets/area/city';
import addResume from './add';
import markResume from './mark';
import editResume from './edit';
import viewResume from './view';
import batchRecommendResume from './batchRecommend';
import batchMark from './batchMark'
import recommendResume from  './recommend'
import importResume from  './import'
import PreViewDocument from "./preView";

export default {
    name: 'resumeList',
    components: {PreViewDocument ,markResume,deptTreeSelect , Treeselect, recommendResume, addResume, editResume, viewResume, batchRecommendResume, batchMark, importResume},
    data() {
        return {
            filter: {
                keywords: null,
                gender: null,
                degree: null,
                label: null,
                intention: null,
                salaryFrom: null,
                salaryTo: null,
                departmentId: null,
            },
            data: {},
            id: null,
            showAddModal: false,
            showEditModal: false,
            showMarkModal: false,
            showViewModal: false,
            showBatchRecommendModal: false,
            showBatchMarkModel: false,
            showRecommendModal: false,
            showImportModal: false,
            showPreViewModal: false,
            loading: false,
            cityOpts: city,
            departmentOpts: [],
            degreeArray: [],
            labelArray: [],
            salaryFromArray: [],
            salaryToArray: [],
            dataList: [],
            selectedMap: new Map(),
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false,
                mark: false,
                import: false,
                export: false,
                recommend: false,
                batchMark: false,
                batchRecommend: false,
                batchDel: false,
                download: false,
                preview: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                },
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                },
                {
                    title: '姓名',
                    key: 'name',
                    width: 100,
                },
                {
                    title: '性别',
                    key: 'gender',
                    minWidth: 80,
                },
                {
                    title: '年龄',
                    key: 'age',
                    width: 80,
                },
                {
                    title: '手机号码',
                    key: 'phone',
                    width: 120,
                },
                {
                    title: '应聘职位',
                    key: 'applyPosition',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '意向城市',
                    key: 'intentionName',
                    tooltip: 'true',
                    minWidth: 180,
                },
                {
                    title: '最高学历',
                    key: 'highestDegreeName',
                    width: 150,
                },
                {
                    title: '上传人',
                    key: 'createByName',
                    width: 120,
                },
                {
                    title: '状态',
                    key: 'labelName',
                    width: 100,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getLabel() {
            reqGetDictionaryListByCode({code: "resume_label"}).then((res) => {
                this.labelArray = res.data.data
            }).catch(() => {
                this.labelArray = []
            })
        },
        getDegree() {
            reqGetDictionaryListByCode({code: "highest_degree"}).then((res) => {
                this.degreeArray = res.data.data
            }).catch(() => {
                this.degreeArray = []
            })
        },
        buildSalaryArray() {
            this.salaryFromArray = [];
            this.salaryToArray = [];
            for (let i=1; i<=500; i++) {
                this.salaryFromArray.push({id: i, name: i + '万'});
                this.salaryToArray.push({id: i, name: i + '万'});
            }
        },
        setSalaryToMax(val) {
            if ('undefined' === typeof val) {
                this.salaryToArray = [];
                for (let i=1; i<=500; i++) {
                    this.salaryToArray.push({id: i, name: i + '万'});
                }
            } else {
                this.salaryToArray = [];
                for (let i=val+1; i<=500; i++) {
                    this.salaryToArray.push({id: i, name: i + '万'});
                }
            }
        },
        handleAdd() {
            this.showAddModal = true;
        },
        handleBatchRecommend() {
            this.showBatchRecommendModal = true;
        },
        handleBatchMark() {
            this.showBatchMarkModel = true;
        },
        handleBatchDel() {
            this.$Modal.confirm({
                title: '批量删除',
                content: '确定批量删除所选简历？此操作不可逆。',
                width: '320',
                onOk: () => {
                    let params =  Array.from(this.selectedMap.keys())
                    reqResumeBatchDel(params).then((res) => {
                        if (res.data.code === 1) {
                            this.getResume();
                            this.$Message.success('批量删除成功');
                            this.selectedMap.clear()
                        } else {
                            this.$Message.error('批量删除失败');
                        }
                    })
                },
            })
        },
        handleImport() {
            this.showImportModal = true;
        },
        handleBatchExport() {
            this.$Modal.confirm({
                title: '批量导出简历',
                content: '确定导出所选简历？',
                width: '320',
                onOk: () => {
                    this.$Loading.start();
                    let params =  Array.from(this.selectedMap.keys())
                    reqBatchExportResume(params).then((res) => {
                        if (res.data.size > 0) {
                            this.$Loading.finish();
                            var blob = new Blob([res.data], {
                                type: 'application/zip',
                            })
                            var downloadElement = document.createElement('a')
                            var href = window.URL.createObjectURL(blob) //创建下载的链接
                            downloadElement.href = href
                            downloadElement.download = "简历_"+new Date().toLocaleDateString()+".zip" //下载后文件名
                            document.body.appendChild(downloadElement)
                            downloadElement.click() //点击下载
                            document.body.removeChild(downloadElement) //下载完成移除元素
                            window.URL.revokeObjectURL(href) //释放掉blob对象
                            this.selectedMap.clear();
                            this.getResume();
                        } else {
                            this.$Loading.error();
                            this.$Message.error('导出失败')
                        }
                    }).catch(()=>{
                        this.$Loading.error();
                        this.$Message.error('导出失败')
                    })
                },
            })
        },
        handleExport(row, index) {
            this.$Modal.confirm({
                title: '导出简历',
                content: '确定导出' + row.name + '的简历？',
                width: '320',
                onOk: () => {
                    this.$Loading.start();
                    reqExportResume(row).then((res)=>{
                        if (res.data.size > 0) {
                            this.$Loading.finish();
                            var blob = new Blob([res.data], {
                                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            })
                            var downloadElement = document.createElement('a')
                            var href = window.URL.createObjectURL(blob) //创建下载的链接
                            downloadElement.href = href
                            downloadElement.download = row.name +"_"+ row.id +"_简历.docx" //下载后文件名
                            document.body.appendChild(downloadElement)
                            downloadElement.click() //点击下载
                            document.body.removeChild(downloadElement) //下载完成移除元素
                            window.URL.revokeObjectURL(href) //释放掉blob对象
                        } else {
                            this.$Loading.error();
                            this.$Message.error('导出失败')
                        }
                    }).catch(()=>{
                        this.$Loading.error();
                        this.$Message.error('导出失败')
                    })
                },
            })
        },
        handleEdit(row, index) {
            this.id = row.id
            this.showEditModal = true;
        },
        handleRecommend(row, index) {
            this.data = row
            this.showRecommendModal = true;
        },
        handleView(row, index) {
            this.id = row.id
            this.showViewModal = true;
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除简历',
                content: '确定删除简历：ID:'+row.id+'？此操作不可逆。',
                width: '320',
                onOk: () => {
                    reqDeleteResume({id:row.id}).then((res) => {
                        if (res.data.code === 1) {
                            this.getResume();
                            this.$Message.success('删除成功');
                            this.selectedMap.clear()
                        } else {
                            this.$Message.error('删除失败');
                        }
                    })
                },
            })
        },
        handleMark(row, index) {
            this.data = row
            this.showMarkModal = true;
        },
        handleDownload(row, index) {
            if (!row.fileName) {
                this.$Message.error('简历原件不存在');
                return;
            }

            let para = {
                type: 'private',
                fileName: row.fileName
            }
            downloadFile(para).then((res) => {
                if (res.data.size > 0) {
                    let blob = new Blob([res.data])
                    let downloadElement = document.createElement('a')
                    let href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = row.originalFileName //下载后文件名
                    document.body.appendChild(downloadElement)
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                } else {
                    this.$Message.error('下载失败')
                }
            }).catch(() => {
                this.$Message.error('下载失败')
            })
        },
        handleSelect(selection, row) {
            this.selectedMap.set(row.id, row.name + '(ID:' + row.id + ')');
            // 重构map，否则页面不更新
            this.selectedMap = new Map(this.selectedMap);
        },
        handleCancel(selection, row) {
            this.selectedMap.delete(row.id);
            // 重构map，否则页面不更新
            this.selectedMap = new Map(this.selectedMap);
        },
        handleSelectAll(selection) {
            selection.forEach((item) => {
                this.selectedMap.set(item.id, item.name + '(ID:' + item.id + ')');
            });
            // 重构map，否则页面不更新
            this.selectedMap = new Map(this.selectedMap);
        },
        handleAllCancel(selection) {
            this.dataList.forEach((item) => {
                this.selectedMap.delete(item.id);
            });
            // 重构map，否则页面不更新
            this.selectedMap = new Map(this.selectedMap);
        },
        handleUncheck(){
            this.selectedMap.clear()
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getResume();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getResume();
        },
        getResume() {
            let that = this;
            that.loading = true;
            that.filter.pageNum = that.pageNum;
            that.filter.pageSize = that.pageSize;
            reqGetResume(that.filter).then((res) => {
                this.loading = false
                that.total = res.data.data.total;
                that.pages = res.data.data.pages;
                that.dataList = res.data.data.records;
            }).catch((err) => {
                this.loading = false;
                console.log("err====", err);
            });
        },
        resetting() {
            let that = this;
            that.loading = true;
            that.filter.keywords= null;
            that.filter.gender= null;
            that.filter.degree= null;
            that.filter.label= null;
            that.filter.intention= null;
            that.filter.salaryFrom= null;
            that.filter.salaryTo= null;
            that.filter.departmentId= null;
            that.filter.pageNum = 1;
            that.filter.pageSize = 20;
            reqGetResume(that.filter).then((res) => {
                this.loading = false
                that.total = res.data.data.total;
                that.pages = res.data.data.pages;
                that.dataList = res.data.data.records;
            }).catch((err) => {
                this.loading = false;
                console.log("err====", err);
            });
        },
        getDepartmentTreeOpts() {
            reqGetDepartmentTreeOpts().then(res => {
                this.departmentOpts = res.data.data;
            }).catch(()=>{
                this.departmentOpts = [];
            });
        },
        handlePreView(row,index){
            this.data = row
            this.showPreViewModal = true
        },
        doSearching() {
            this.pageNum = 1;
            this.getResume();
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId
        permission.getPermissionButton(permissionId, this.permissionButton);
        this.getDegree();
        this.getLabel();
        this.buildSalaryArray();
        this.getResume();
        this.getDepartmentTreeOpts();
    }
}
</script>
