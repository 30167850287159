<template>
    <Modal v-model="showForm" title='状态标记' :mask-closable="false">
        <Form ref="batchMarkForm" :model="batchMarkForm" :rules="batchMarkFormRule" label-colon :label-width="90">
            <div class="divider">简历信息</div>
            <FormItem label="姓名">{{ Array.from(data.values()).join('，') }}</FormItem>
            <div class="divider">简历状态</div>
            <FormItem label="标记状态" prop="label">
                <Select v-model="batchMarkForm.label" placeholder="请选择状态标记" filterable clearable>
                    <Option v-for="item in labelArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>


import {reqGetDictionaryListByCode} from "@/api/system-api";
import {reqBatchMarkResume} from "@/api/admin-api";

export default {
    name: "batchMark",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: '',
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            batchMarkForm: {
                label: null
            },
            labelArray: [],
            batchMarkFormRule: {
                label:[
                    {required: true, message: '请选择状态标记',type: 'integer', trigger: 'change'}
                ]
            },
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            let params = {
                resumeIds: Array.from(this.data.keys()),
                label: this.batchMarkForm.label
            }
            reqBatchMarkResume(params).then((res)=>{
                this.loading = false;
                if (res.data.code === 1){
                    this.$Message.success('标记成功');
                    this.$emit('getResume');
                    this.$emit('handleUncheck')
                    this.closeModal(false);
                }
            }).catch(()=>{
                this.$Message.success('标记失败');
                this.closeModal(false);
            })
        },
        getLabel() {
            reqGetDictionaryListByCode({code: "resume_label"}).then((res) => {
                this.labelArray = res.data.data
            }).catch(() => {
                this.labelArray = []
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.getLabel()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
