<template>
    <Modal v-model="showForm" title="简历导入" :mask-closable="false">
        <Form ref="importForm" label-colon :label-width="130">
            <div class="custom-form">
                <div class="divider">简历列表</div>
                <div class="submit-qual">
                    <div class="submit-qual-section">
                        <div class="submit-qual-section-block">
                            <Alert type="warning" show-icon v-for="(error, index) in errorArray" :key="index">{{error}}</Alert>
                            <div class="headline">选择简历</div>
                            <div class="tips">要求：支持类型为.docx, .doc, .zip格式文件，单个文件10M以内</div>
                            <div class="upload">
                                <Upload ref="uploadResume"
                                        type="drag"
                                        multiple
                                        :headers="header"
                                        :max-size="10 * 1024"
                                        :action="uploadFiles"
                                        :data="{type: 'private'}"
                                        :format="['docx','doc','zip']"
                                        accept=".docx,.doc,.zip"
                                        :on-exceeded-size="handExceeded"
                                        :on-success="handleSuccess">
                                    <div class="upload-btn-l">
                                        <Icon type="md-cloud-upload"></Icon>
                                        <p class="tips">点击上传或拖动文件到这里上传</p>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqImportResume} from "../../api/admin-api";
import {uploadFileURL} from "../../api/system-api";

export default {
    name: "importResume",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: '',
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            header: {Authorization: sessionStorage.getItem('Authorization')},
            uploadFiles: uploadFileURL,
            errorArray: [],
            resumes: []
        }
    },
    methods: {
        //上传文件大小
        handExceeded(file) {
            this.$Message.error('文件大小不得超过10M');
        },
        //上传成功
        handleSuccess(res, file, fileList) {
            if (res.code === 1) {
                this.resumes.push(res.data);
                this.errorArray = [];
            } else {
                this.$Message.error('上传失败');
            }
        },
        handleSubmit() {
            this.loading = true;
            if (!this.resumes || this.resumes.length === 0) {
                this.errorArray.push('请选择简历');
                this.loading = false;
                return false;
            }

            reqImportResume({type: 'private', resumeList: this.resumes}).then((res) => {
                this.loading = false;
                if (res.data.code === 1) {
                    if (res.data.data.success) {
                        this.$Message.success('导入成功');
                        this.$emit('getResume');
                        this.closeModal(false);
                    } else {
                        res.data.data.failedArray.map(item => {
                            this.errorArray.push(item.orignalName + ' ' + item.error);
                        })
                    }
                } else {
                    this.$Message.error('导入失败');
                }
            }).catch(() => {
                this.loading = false;
                this.$Message.error('导入失败');
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
            } else {// 反之则关闭页面
                this.$refs.uploadResume.clearFiles();
                this.errorArray = [];
                this.resumes = [];
                this.closeModal(val);
            }
        }
    }
}
</script>
