<template>
    <Modal v-model="showForm" title="新增简历" :mask-closable="false" width="720">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="130">
            <div class="custom-form">
                <div class="divider">职位信息</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="应聘职位" prop="applyPosition">
                                <Input type="text" show-word-limit :maxlength="50" v-model.trim="addForm.applyPosition" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="应聘部门" prop="applyDept">
                                <Input type="text" show-word-limit :maxlength="50" v-model.trim="addForm.applyDept" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                    </Row>
                </div>
                <div class="divider">简历原件<span class="tips">注：只能上传一份文档</span></div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="上传简历">
                                <Upload ref="resumeUpload" type="drag"
                                        :headers="header"
                                        :max-size="10 * 1024"
                                        :action="uploadFiles"
                                        :data="{type: 'private'}"
                                        :format="['doc', 'docx', 'pdf']"
                                        accept=".doc,.docx,.pdf"
                                        :show-upload-list="false"
                                        :on-exceeded-size="(file) => handExceeded(file, 10)"
                                        :on-success="handleFileSuccess">
                                    <div class="upload-btn-l">
                                        <Icon type="md-cloud-upload"></Icon>
                                        <p class="tips">要求：支持.doc, .docx, .pdf格式, 10M以内</p>
                                    </div>
                                </Upload>
                                <div class="upload-list">
                                    <a @click="handleDownload(addForm.originalFileName, addForm.fileName)">{{ addForm.originalFileName }}</a>
                                </div>
                            </FormItem>
                        </Col>
                    </Row>
                </div>
                <div class="divider">个人资料</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="照片" prop="photo">
                                <Upload ref="upload" type="drag"
                                        :headers="header"
                                        :max-size="5 * 1024"
                                        :action="uploadFiles"
                                        :data="{type: 'private'}"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :on-exceeded-size="(file) => handExceeded(file, 5)"
                                        :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'photoImgArray')"
                                        v-if="photoImgArray.length < 1">
                                    <div class="upload-btn-l">
                                        <Icon type="md-cloud-upload"></Icon>
                                        <p class="tips">要求：一寸蓝底或白底免冠照片，支持.jpg, .jpeg, .png格式, 5M以内</p>
                                    </div>
                                </Upload>
                                <div class="upload-image-list" v-for="(item, index) in photoImgArray">
                                    <img :src="item.imgData" style=""/>
                                    <div class="upload-image-list-cover">
                                        <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        <Icon type="ios-trash" @click.native="handleRemove('photoImgArray', index)"></Icon>
                                    </div>
                                </div>
                            </FormItem>
                        </Col>
                    </Row>
                    <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
                        <img :src="imgView" v-if="showPreview" style="width: 100%"/>
                    </Modal>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="姓名" prop="name">
                                <Input type="text" show-word-limit :maxlength="10" v-model.trim="addForm.name" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="曾用名" prop="formerName">
                                <Input type="text" show-word-limit :maxlength="10" v-model.trim="addForm.formerName" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="性别" prop="gender">
                                <RadioGroup v-model="addForm.gender">
                                    <Radio label="男">男</Radio>
                                    <Radio label="女">女</Radio>
                                </RadioGroup>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="出生年份" prop="birthday">
                                <DatePicker type="year" placeholder="请选择" v-model="addForm.birthday" :options="pastOptions"></DatePicker>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="籍贯" prop="birthplace">
                                <treeselect v-model="addForm.birthplace"
                                            :max-height="130"
                                            :options="countyOpts"
                                            :disable-branch-nodes="true"
                                            placeholder="请选择"
                                            loadingText="加载中..."
                                            noOptionsText="暂无数据"
                                            noResultsText="没有匹配数据"
                                            @select="(node, instanceId) => handleCountySelected(node, instanceId, 'birthplaceName')">
                                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.full_label }}</div>
                                </treeselect>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="民族" prop="nation">
                                <Select v-model="addForm.nation" filterable clearable label-in-value @on-change="(obj) => handleSelectedChange(obj, 'nationName')">
                                    <Option v-for="item in dict.nation" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="身高(cm)" prop="height">
                                <InputNumber v-model="addForm.height" placeholder="请输入" :max="300" :min="1" :step="1"></InputNumber>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="体重(Kg)" prop="weight">
                                <InputNumber v-model="addForm.weight" placeholder="请输入" :max="500" :min="1" :step="1"></InputNumber>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="手机号码" prop="phone">
                                <Input type="text" show-word-limit :maxlength="11" v-model.trim="addForm.phone" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="身份证号码" prop="idNumber">
                                <Input type="text" show-word-limit :maxlength="18" v-model.trim="addForm.idNumber" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="健康状况" prop="healthy">
                                <Select v-model="addForm.healthy" filterable clearable label-in-value @on-change="(obj) => handleSelectedChange(obj, 'healthyName')">
                                    <Option v-for="item in dict.health_condition" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="婚姻状况" prop="maritalStatus">
                                <Select v-model="addForm.maritalStatus" filterable clearable label-in-value @on-change="(obj) => handleSelectedChange(obj, 'maritalStatusName')">
                                    <Option v-for="item in dict.marital_status" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="政治面貌" prop="politics">
                                <Select v-model="addForm.politics" filterable clearable label-in-value @on-change="(obj) => handleSelectedChange(obj, 'politicsName')">
                                    <Option v-for="item in dict.politics_status" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="入党时间" prop="partyDate" v-if="addForm.politics === 202703">
                                <DatePicker type="date" placeholder="请选择" v-model="addForm.partyDate" :options="pastOptions"></DatePicker>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="第一学历" prop="firstDegree">
                                <Select v-model="addForm.firstDegree" filterable clearable label-in-value @on-change="(obj) => handleSelectedChange(obj, 'firstDegreeName')">
                                    <Option v-for="item in dict.highest_degree" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="最高学历" prop="highestDegree">
                                <Select v-model="addForm.highestDegree" filterable clearable label-in-value @on-change="(obj) => handleSelectedChange(obj, 'highestDegreeName')">
                                    <Option v-for="item in dict.highest_degree" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="参加工作时间" prop="startWorkFrom">
                                <DatePicker type="date" placeholder="请选择" v-model="addForm.startWorkFrom" :options="pastOptions"></DatePicker>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="邮箱" prop="email" :rules="addFormRule.emailRequiredValidator">
                                <Input type="text" show-word-limit :maxlength="60" v-model.trim="addForm.email" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="户口所在地" prop="domicilePlace">
                                <treeselect v-model="addForm.domicilePlace"
                                            :max-height="130"
                                            :options="countyOpts"
                                            :disable-branch-nodes="true"
                                            placeholder="请选择"
                                            loadingText="加载中..."
                                            noOptionsText="暂无数据"
                                            noResultsText="没有匹配数据"
                                            @select="(node, instanceId) => handleCountySelected(node, instanceId, 'domicilePlaceName')">
                                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.full_label }}</div>
                                </treeselect>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="家庭详细地址" prop="homeAddress">
                                <Input type="text" show-word-limit :maxlength="100" v-model.trim="addForm.homeAddress" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="通信地址" prop="mailingAddress">
                                <Input type="text" show-word-limit :maxlength="100" v-model.trim="addForm.mailingAddress" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="意向城市" prop="cityIdArray">
                                <treeselect v-model="addForm.cityIdArray"
                                            :multiple="true"
                                            :max-height="130"
                                            :options="cityOpts"
                                            :clear-on-select="clearOnSelect"
                                            placeholder="请选择"
                                            loadingText="加载中..."
                                            noOptionsText="暂无数据"
                                            noResultsText="没有匹配数据">
                                </treeselect>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="职称及职业资格" prop="jobTitleId">
                                <Select v-model="addForm.jobTitleId" multiple filterable clearable>
                                    <Option v-for="item in dict.job_title" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="爱好和特长" prop="hobby" :rules="addFormRule.editorContentLength">
                                <quill-editor v-model="addForm.hobby" :options="editorOption"></quill-editor>
                            </FormItem>
                            <FormItem label="现居住地" prop="address">
                                <Input type="text" show-word-limit :maxlength="100" v-model.trim="addForm.address" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="紧急联系人姓名" prop="ecn">
                                <Input type="text" show-word-limit :maxlength="10" v-model.trim="addForm.ecn" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="紧急联系人电话" prop="ecp">
                                <Input type="text" show-word-limit :maxlength="11" v-model.trim="addForm.ecp" placeholder="请输入"/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="目前税前年薪(万元)" prop="currentAnnualSalary">
                                <InputNumber v-model="addForm.currentAnnualSalary" placeholder="请输入" :max="500" :min="1" :step="1"></InputNumber>
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="期望年薪(万元)" prop="expectAnnualSalary">
                                <InputNumber v-model="addForm.expectAnnualSalary" placeholder="请输入" :max="500" :min="1" :step="1"></InputNumber>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="到岗时间" prop="dutyTime">
                                <DatePicker type="date" placeholder="请选择" v-model="addForm.dutyTime" :options="futureOptions"></DatePicker>
                            </FormItem>
                            <FormItem label="是否服从调配" prop="adjustable">
                                <RadioGroup v-model="addForm.adjustable">
                                    <Radio label="是">是</Radio>
                                    <Radio label="否">否</Radio>
                                </RadioGroup>
                            </FormItem>
                            <FormItem label="组织关系所在地" prop="mcl">
                                <Input type="text" show-word-limit :maxlength="100" v-model.trim="addForm.mcl" placeholder="请输入"/>
                            </FormItem>
                            <FormItem label="自我评价" prop="selfEvaluation" :rules="addFormRule.editorContentLength">
                                <quill-editor v-model="addForm.selfEvaluation" :options="editorOption"></quill-editor>
                            </FormItem>
                        </Col>
                    </Row>
                </div>
                <div class="divider">外语水平</div>
                <div class="custom-form-section">
                    <div v-for="(obj, index) in addForm.language" :class="index===0 ? 'custom-form-section-default' : 'custom-form-section-block divider-line'" :key="index">
                        <Row :gutter="16">
                            <Col span="12">
                                <FormItem label="语种" :prop="`language.${index}.language_1value`">
                                    <Select v-model="obj.language_1value" filterable clearable label-in-value @on-change="(val) => _handleSelectedChange(val, index, 'language', 'language_1')">
                                        <Option v-for="item in dict.language" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span="12">
                                <FormItem label="掌握程度" :prop="`language.${index}.language_2value`">
                                    <Select v-model="obj.language_2value" filterable clearable label-in-value @on-change="(val) => _handleSelectedChange(val, index, 'language', 'language_2')">
                                        <Option v-for="item in dict.level" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Button class="remove-btn" type="text" size="small" @click="removeLanguage(index)">- 移除</Button>
                    </div>
                    <div class="custom-form-section-btn">
                        <Button type="text" @click="addMoreLanguage">+ 添加更多</Button>
                    </div>
                </div>
                <div class="divider">教育经历<span class="tips">注：教育经历从高中起，倒序填写</span></div>
                <div class="custom-form-section">
                    <div v-for="(obj, index) in addForm.education" :class="index===0 ? 'custom-form-section-default' : 'custom-form-section-block divider-line'">
                        <Row :gutter="16">
                            <Col span="24">
                                <FormItem label="起止年月" :prop="`education.${index}.education_1value`" :rules="addFormRule.dateRangeValidator">
                                    <Row :gutter="16">
                                        <Col span="11">
                                            <DatePicker v-model="obj.education_1value" type="month" placeholder="请选择" :options="disabledMonthOptions"></DatePicker>
                                        </Col>
                                        <Col class="text-center" span="2">
                                            至
                                        </Col>
                                        <Col span="11">
                                            <DatePicker v-model="obj.education_8value" type="month" placeholder="请选择" :options="disabledMonthOptions"></DatePicker>
                                        </Col>
                                    </Row>
                                </FormItem>
                                <FormItem label="毕业学校" :prop="`education.${index}.education_2value`">
                                    <Input type="text" show-word-limit :maxlength="50" v-model.trim="obj.education_2value" placeholder="请输入"/>
                                </FormItem>
                                <FormItem label="专业" :prop="`education.${index}.education_3value`">
                                    <Input type="text" show-word-limit :maxlength="50" v-model.trim="obj.education_3value" placeholder="请输入"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row :gutter="16">
                            <Col span="12">
                                <FormItem label="学历" :prop="`education.${index}.education_4value`">
                                    <Select v-model="obj.education_4value" filterable clearable label-in-value @on-change="(val) => _handleSelectedChange(val, index, 'education', 'education_4')">
                                        <Option v-for="item in dict.highest_degree" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span="12">
                                <FormItem label="学位" :prop="`education.${index}.education_5value`">
                                    <Select v-model="obj.education_5value" filterable clearable label-in-value @on-change="(val) => _handleSelectedChange(val, index, 'education', 'education_5')">
                                        <Option v-for="item in dict.degree" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row :gutter="16">
                            <Col span="24">
                                <FormItem label="教育方式" :prop="`education.${index}.education_6value`">
                                    <RadioGroup v-model="obj.education_6value">
                                        <Radio v-for="obj in dict.educational_mode" :label="obj.name" :key="obj.id">{{obj.name}}</Radio>
                                    </RadioGroup>
                                </FormItem>
                                <FormItem label="证明人" :prop="`education.${index}.education_7value`">
                                    <Input type="text" show-word-limit :maxlength="20" v-model.trim="obj.education_7value" placeholder="请输入"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Button class="remove-btn" type="text" size="small" @click="removeEducation(index)">- 移除</Button>
                    </div>
                    <div class="custom-form-section-btn">
                        <Button type="text" @click="addMoreEducation">+ 添加更多</Button>
                    </div>
                </div>
                <div class="divider">工作经历<span class="tips">注：工作经历不含实习经历，倒序填写</span></div>
                <div class="custom-form-section">
                    <div v-for="(obj, index) in addForm.work" :class="index===0 ? 'custom-form-section-default' : 'custom-form-section-block divider-line'">
                        <Row :gutter="16">
                            <Col span="24">
                                <FormItem label="起止年月" :prop="`work.${index}.work_1value`" :rules="addFormRule.dateRangeRequiredValidator">
                                    <Row :gutter="16">
                                        <Col span="11">
                                            <DatePicker v-model="obj.work_1value" type="month" placeholder="请选择" :options="disabledMonthOptions"></DatePicker>
                                        </Col>
                                        <Col class="text-center" span="2">
                                            至
                                        </Col>
                                        <Col span="11">
                                            <DatePicker v-model="obj.work_8value" type="month" placeholder="请选择" :options="disabledMonthOptions"></DatePicker>
                                        </Col>
                                    </Row>
                                </FormItem>
                                <FormItem label="工作单位" :prop="`work.${index}.work_2value`" :rules="[{required: true, message: '请输入', trigger: 'blur'}]">
                                    <Input type="text" show-word-limit :maxlength="50" v-model.trim="obj.work_2value" placeholder="请输入"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row :gutter="16">
                            <Col span="12">
                                <FormItem label="单位性质" :prop="`work.${index}.work_3value`">
                                    <Select v-model="obj.work_3value" filterable clearable label-in-value @on-change="(val) => _handleSelectedChange(val, index, 'work', 'work_3')">
                                        <Option v-for="item in dict.nature_of_business" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span="12">
                                <FormItem label="职务/职位" :prop="`work.${index}.work_4value`" :rules="[{required: true, message: '请输入', trigger: 'blur'}]">
                                    <Input type="text" show-word-limit :maxlength="50" v-model.trim="obj.work_4value" placeholder="请输入"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row :gutter="16">
                            <Col span="12">
                                <FormItem label="证明人" :prop="`work.${index}.work_5value`">
                                    <Input type="text" show-word-limit :maxlength="20" v-model.trim="obj.work_5value" placeholder="请输入"/>
                                </FormItem>
                            </Col>
                            <Col span="12">
                                <FormItem label="联系电话" :prop="`work.${index}.work_6value`" :rules="addFormRule.contactNumberValidator">
                                    <Input type="text" show-word-limit :maxlength="20" v-model.trim="obj.work_6value" placeholder="请输入"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row :gutter="16">
                            <Col span="24">
                                <FormItem label="工作内容及业绩" :prop="`work.${index}.work_7value`" :rules="addFormRule.requiredEditorContentLength">
                                    <quill-editor v-model="obj.work_7value" :options="editorOption"></quill-editor>
                                </FormItem>
                                <FormItem label="离职原因" :prop="`work.${index}.work_9value`" :rules="addFormRule.editorContentLength">
                                    <quill-editor v-model="obj.work_9value" :options="editorOption"></quill-editor>
                                </FormItem>
                            </Col>
                        </Row>
                        <Button class="remove-btn" type="text" size="small" v-if="index > 0" @click="removeWork(index)">- 移除</Button>
                    </div>
                    <div class="custom-form-section-btn">
                        <Button type="text" @click="addMoreWork">+ 添加更多</Button>
                    </div>
                </div>
                <div class="divider">项目经验</div>
                <div class="custom-form-section">
                    <div v-for="(obj, index) in addForm.project" :class="index===0 ? 'custom-form-section-default' : 'custom-form-section-block divider-line'">
                        <Row :gutter="16">
                            <Col span="24">
                                <FormItem label="项目名称" :prop="`project.${index}.project_1value`">
                                    <Input type="text" show-word-limit :maxlength="50" v-model.trim="obj.project_1value" placeholder="请输入"/>
                                </FormItem>
                                <FormItem label="项目描述" :prop="`project.${index}.project_2value`" :rules="addFormRule.editorContentLength">
                                    <quill-editor v-model="obj.project_2value" :options="editorOption"></quill-editor>
                                </FormItem>
                                <FormItem label="项目职责" :prop="`project.${index}.project_3value`" :rules="addFormRule.editorContentLength">
                                    <quill-editor v-model="obj.project_3value" :options="editorOption"></quill-editor>
                                </FormItem>
                                <FormItem label="项目业绩" :prop="`project.${index}.project_4value`" :rules="addFormRule.editorContentLength">
                                    <quill-editor v-model="obj.project_4value" :options="editorOption"></quill-editor>
                                </FormItem>
                            </Col>
                        </Row>
                        <Button class="remove-btn" type="text" size="small" @click="removeProject(index)">- 移除</Button>
                    </div>
                    <div class="custom-form-section-btn">
                        <Button type="text" @click="addMoreProject">+ 添加更多</Button>
                    </div>
                    <div class="custom-form-section-block divider-line no-hover" v-if="addForm.project?.length > 0">
                        <Row :gutter="16">
                            <Col span="24">
                                <FormItem label="重大奖惩情况" prop="reward" :rules="addFormRule.editorContentLength">
                                    <quill-editor v-model="addForm.reward" :options="editorOption"></quill-editor>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div class="divider">家庭主要成员<span class="tips">注：填写主要家庭成员</span></div>
                <div class="custom-form-section">
                    <div v-for="(obj, index) in addForm.family" :class="index===0 ? 'custom-form-section-default' : 'custom-form-section-block divider-line'">
                        <Row :gutter="16">
                            <Col span="24">
                                <FormItem label="姓名" :prop="`family.${index}.family_1value`">
                                    <Input type="text" show-word-limit :maxlength="20" v-model.trim="obj.family_1value" placeholder="请输入"/>
                                </FormItem>
                                <FormItem label="出生年份" :prop="`family.${index}.family_2value`">
                                    <DatePicker v-model="obj.family_2value" type="year" placeholder="请选择" :options="pastOptions"></DatePicker>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row :gutter="16">
                            <Col span="12">
                                <FormItem label="关系" :prop="`family.${index}.family_3value`">
                                    <Select v-model="obj.family_3value" filterable clearable label-in-value @on-change="(val) => _handleSelectedChange(val, index, 'family', 'family_3')">
                                        <Option v-for="item in dict.relationship" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span="12">
                                <FormItem label="政治面貌" :prop="`family.${index}.family_4value`">
                                    <Select v-model="obj.family_4value" filterable clearable label-in-value @on-change="(val) => _handleSelectedChange(val, index, 'family', 'family_4')">
                                        <Option v-for="item in dict.politics_status" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row :gutter="16">
                            <Col span="12">
                                <FormItem label="工作单位及职务" :prop="`family.${index}.family_5value`">
                                    <Input type="text" show-word-limit :maxlength="100" v-model.trim="obj.family_5value" placeholder="请输入"/>
                                </FormItem>
                            </Col>
                            <Col span="12">
                                <FormItem label="联系电话" :prop="`family.${index}.family_6value`" :rules="addFormRule.contactNumberValidator">
                                    <Input type="text" show-word-limit :maxlength="20" v-model.trim="obj.family_6value" placeholder="请输入"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Button class="remove-btn" type="text" size="small" @click="removeFamily(index)">- 移除</Button>
                    </div>
                    <div class="custom-form-section-btn">
                        <Button type="text" @click="addMoreFamily">+ 添加更多</Button>
                    </div>
                </div>
                <div class="divider">资质</div>
                <div class="submit-qual">
                    <div class="submit-qual-section">
                        <div class="submit-qual-section-block">
                            <div class="headline">身份证扫描件</div>
                            <div class="tips">要求：正反面身份证件扫描件，支持jpg, JPG, png, JPEG格式，单个文件5M以内</div>
                            <div class="upload">
                                <div class="upload-image-list" v-for="(item, index) in idImgArray">
                                    <img :src="item.imgData" style=""/>
                                    <div class="upload-image-list-cover">
                                        <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        <Icon type="ios-trash" @click.native="handleRemove('idImgArray', index)"></Icon>
                                    </div>
                                </div>
                                <Upload multiple type="drag"
                                        :headers="header"
                                        :max-size="5 * 1024"
                                        :action="uploadFiles"
                                        :data="{type: 'private'}"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :before-upload="(file) => handleBeforeUpload(file, 2)"
                                        :on-exceeded-size="(file) => handExceeded(file, 5)"
                                        :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'idImgArray')"
                                        v-if="idImgArray.length < 2">
                                    <div class="upload-btn-l">
                                        <Icon type="md-cloud-upload"></Icon>
                                        <p class="tips">点击上传或拖动图片到这里上传</p>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                        <div class="submit-qual-section-block divider-line">
                            <div class="headline">近期一寸免冠电子证件照片</div>
                            <div class="tips">要求：蓝底或白底一寸免冠电子照片，支持jpg, JPG, png, JPEG格式，单个5M以内</div>
                            <div class="upload">
                                <div class="upload-image-list" v-for="(item, index) in bareheadedImgArray">
                                    <img :src="item.imgData" style=""/>
                                    <div class="upload-image-list-cover">
                                        <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        <Icon type="ios-trash" @click.native="handleRemove('bareheadedImgArray', index)"></Icon>
                                    </div>
                                </div>
                                <Upload type="drag"
                                        :headers="header"
                                        :max-size="5 * 1024"
                                        :action="uploadFiles"
                                        :data="{type: 'private'}"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :on-exceeded-size="(file) => handExceeded(file, 5)"
                                        :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'bareheadedImgArray')"
                                        v-if="bareheadedImgArray.length < 1">
                                    <div class="upload-btn-l">
                                        <Icon type="md-cloud-upload"></Icon>
                                        <p class="tips">点击上传或拖动图片到这里上传</p>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                        <div class="submit-qual-section-block divider-line">
                            <div class="headline">所有学历，学位证书扫描件</div>
                            <div class="tips">要求：留学人员需上传教育部中国留学服务中心出具的国（境）外学历学位认证书，支持jpg, JPG, png, JPEG格式，单个文件5M以内</div>
                            <div class="upload">
                                <div class="upload-image-list" v-for="(item, index) in degreeImgArray">
                                    <img :src="item.imgData" style=""/>
                                    <div class="upload-image-list-cover">
                                        <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        <Icon type="ios-trash" @click.native="handleRemove('degreeImgArray', index)"></Icon>
                                    </div>
                                </div>
                                <Upload multiple type="drag"
                                        :headers="header"
                                        :max-size="5 * 1024"
                                        :action="uploadFiles"
                                        :data="{type: 'private'}"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :before-upload="(file) => handleBeforeUpload(file, 5)"
                                        :on-exceeded-size="(file) => handExceeded(file, 5)"
                                        :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'degreeImgArray')"
                                        v-if="degreeImgArray.length < 5">
                                    <div class="upload-btn-l">
                                        <Icon type="md-cloud-upload"></Icon>
                                        <p class="tips">点击上传或拖动图片到这里上传</p>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                        <div class="submit-qual-section-block divider-line">
                            <div class="headline">所有学历《教育部学历证书电子注册备案表》</div>
                            <div class="tips">要求：从学信网下载PDF版且在有效期内，支持jpg, JPG, png, JPEG格式，单个文件5M以内</div>
                            <div class="upload">
                                <div class="upload-image-list" v-for="(item, index) in educationImgArray">
                                    <img :src="item.imgData" style=""/>
                                    <div class="upload-image-list-cover">
                                        <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        <Icon type="ios-trash" @click.native="handleRemove('educationImgArray', index)"></Icon>
                                    </div>
                                </div>
                                <Upload multiple type="drag"
                                        :headers="header"
                                        :max-size="5 * 1024"
                                        :action="uploadFiles"
                                        :data="{type: 'private'}"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :before-upload="(file) => handleBeforeUpload(file, 2)"
                                        :on-exceeded-size="(file) => handExceeded(file, 5)"
                                        :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'educationImgArray')"
                                        v-if="educationImgArray.length < 2">
                                    <div class="upload-btn-l">
                                        <Icon type="md-cloud-upload"></Icon>
                                        <p class="tips">点击上传或拖动图片到这里上传</p>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                        <div class="submit-qual-section-block divider-line">
                            <div class="headline">个人相关资格证书、职称扫描件</div>
                            <div class="tips">要求：支持jpg, JPG, png, JPEG格式，单个文件5M以内</div>
                            <div class="upload">
                                <div class="upload-image-list" v-for="(item, index) in personalImgArray">
                                    <img :src="item.imgData" style=""/>
                                    <div class="upload-image-list-cover">
                                        <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        <Icon type="ios-trash" @click.native="handleRemove('personalImgArray', index)"></Icon>
                                    </div>
                                </div>
                                <Upload multiple type="drag"
                                        :headers="header"
                                        :max-size="5 * 1024"
                                        :action="uploadFiles"
                                        :data="{type: 'private'}"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :before-upload="(file) => handleBeforeUpload(file, 5)"
                                        :on-exceeded-size="(file) => handExceeded(file, 5)"
                                        :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'personalImgArray')"
                                        v-if="personalImgArray.length < 5">
                                    <div class="upload-btn-l">
                                        <Icon type="md-cloud-upload"></Icon>
                                        <p class="tips">点击上传或拖动图片到这里上传</p>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                        <div class="submit-qual-section-block divider-line">
                            <div class="headline">其他可以证明符合报名要求的相关资料</div>
                            <div class="tips">要求：支持jpg, JPG, png, JPEG格式，单个文件5M以内</div>
                            <div class="upload">
                                <div class="upload-image-list" v-for="(item, index) in otherImgArray">
                                    <img :src="item.imgData" style=""/>
                                    <div class="upload-image-list-cover">
                                        <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        <Icon type="ios-trash" @click.native="handleRemove('otherImgArray', index)"></Icon>
                                    </div>
                                </div>
                                <Upload multiple type="drag"
                                        :headers="header"
                                        :max-size="5 * 1024"
                                        :action="uploadFiles"
                                        :data="{type: 'private'}"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :before-upload="(file) => handleBeforeUpload(file, 5)"
                                        :on-exceeded-size="(file) => handExceeded(file, 5)"
                                        :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'otherImgArray')"
                                        v-if="otherImgArray.length < 5">
                                    <div class="upload-btn-l">
                                        <Icon type="md-cloud-upload"></Icon>
                                        <p class="tips">点击上传或拖动图片到这里上传</p>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
// 调用富文本编辑器
import {quillEditor} from 'vue-quill-editor';
// 富文本编辑器外部引用样式  三种样式三选一引入即可
import 'quill/dist/quill.snow.css';
import {downloadFile, reqDeleteFiles, reqDictionaryMapByCodeList, uploadFileURL} from "../../api/system-api";
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import county from '../../assets/area/county'
import city from '../../assets/area/city'
import {
    check18IdNumber,
    checkEditorContent, checkEmail,
    checkMobile,
    checkPhone
} from "../../common/publicFuns";
import {reqAddResume} from "../../api/admin-api";
import {getLabelById} from "../../assets/js/treeFun";

export default {
    name: "addResume",
    components: {Treeselect, quillEditor},
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            editorOption: { //  富文本编辑器配置
                modules:{
                    clipboard: {
                        // 粘贴版，处理粘贴时候带图片
                        matchers: [['img', this.handleCustomMatcher]],
                    },
                    toolbar:[
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        ['clean']
                    ]
                },
                theme: 'snow',
                placeholder: '请输入'
            },
            loading: false,
            showForm: this.value,
            showPreview: false,
            header: null,
            uploadFiles: uploadFileURL,
            photoImgArray: [],
            idImgArray: [],
            bareheadedImgArray: [],
            degreeImgArray: [],
            educationImgArray: [],
            personalImgArray: [],
            otherImgArray: [],
            imgView: null,
            fileCounts: 0,
            dict: {job_title: []},
            pastOptions: {
                disabledDate(date) {
                    return date && date.valueOf() > Date.now() - 86400000;
                }
            },
            futureOptions: {
                disabledDate(date) {
                    return date && date.valueOf() <= Date.now() - 86400000;
                }
            },
            disabledMonthOptions: {
                disabledDate (date) {
                    return date && date.valueOf() > new Date();
                }
            },
            countyOpts: county,
            cityOpts: city,
            clearOnSelect: true,
            addForm: {
                applyPosition: null,
                photo: null,
                name: null,
                gender: '男',
                birthday: null,
                jobTitleId: [],
                politics: null,
                partyDate: null,
                height: null,
                weight: null,
                highestDegree: null,
                domicilePlace: null,
                hobby: null,
                phone: null,
                idNumber: null,
                email: null,
                ecp: null,
                currentAnnualSalary: null,
                expectAnnualSalary: null,
                adjustable: '是',
                startWorkFrom: null,
                dutyTime: null,
                cityIdArray: [],
                intention: null,
                intentionName: null,
                language: [],
                education: [],
                work: [],
                project: [],
                reward: null,
                family: [],
                certification: {},
                originalFileName: null,
                fileName: null
            },
            addFormRule: {
                applyPosition: [{required: true, message: '请输入应聘职位', trigger: 'blur'}],
                name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
                gender: [{required: true, message: '请选择性别', trigger: 'blur'}],
                birthday: [{required: true, message: '请选择出生年份', type: 'date', trigger: 'blur'}],
                highestDegree: [{required: true, message: '请选择最高学历', type: 'date', trigger: 'blur'}],
                cityIdArray: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value || value.length === 0) {
                            return callback(new Error('请选择意向城市'));
                        }
                        if (value.length > 10) {
                            return callback(new Error('意向城市最多只能选择10个'));
                        }

                        return callback();
                    }
                }],
                hobby: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkEditorContent(value, 500)) {
                            return callback(new Error('爱好和特长不能超过500字符'));
                        }

                        return callback();
                    }
                }],
                emailRequiredValidator: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkEmail(value)) {
                            return callback('请输入正确的邮箱');
                        }

                        return callback();
                    }
                }],
                phone: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return callback('请输入手机号码');
                        }
                        if (!checkMobile(value)) {
                            return callback('请输入正确的手机号码');
                        }

                        return callback();
                    }
                }],
                idNumber: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !check18IdNumber(value)) {
                            return callback('请输入正确的身份证号码');
                        }

                        return callback();
                    }
                }],
                ecp: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkMobile(value)) {
                            return callback('请输入正确的紧急联系人电话');
                        }

                        return callback();
                    }
                }],
                dateRangeRequiredValidator: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        let path = rule.field.split('.');
                        let objList = this.addForm[path[0]];
                        let obj = objList[path[1] * 1];
                        let newId = path[2].replace('1value', '8value');
                        if (!value || !obj[newId]) {
                            return callback('请选择起止年月');
                        }
                        if (value >= obj[newId]) {
                            return callback('起始年月不能大于截止年月');
                        }

                        return callback();
                    }
                }],
                dateRangeValidator: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        let path = rule.field.split('.');
                        let objList = this.addForm[path[0]];
                        let obj = objList[path[1] * 1];
                        let newId = path[2].replace('1value', '8value');
                        if (value && obj[newId] && value >= obj[newId]) {
                            return callback('起始年月不能大于截止年月');
                        }

                        return callback();
                    }
                }],
                editorContentLength: [{trigger: 'blur',
                    validator: (rule, value, callback, id) => {
                        let name = '';
                        let len = 500;
                        let idx = rule.field.lastIndexOf('.');
                        let itemId = (idx >= 0) ? rule.field.substring(idx + 1) : rule.field;
                        if (itemId === 'work_9value') {
                            name = '离职原因';
                            len = 500;
                        } else if (itemId === 'project_2value') {
                            name = '项目描述';
                            len = 500;
                        } else if (itemId === 'project_3value') {
                            name = '项目职责';
                            len = 500;
                        } else if (itemId === 'project_4value') {
                            name = '项目业绩';
                            len = 500;
                        } else if (itemId === 'reward') {
                            name = '重大奖惩情况';
                            len = 500;
                        } else if (itemId === 'hobby') {
                            name = '爱好和特长';
                            len = 500;
                        } else if (itemId === 'selfEvaluation') {
                            name = '自我评价';
                            len = 500;
                        }

                        if (value && !checkEditorContent(value, 500)) {
                            return callback(new Error(name + '不能超过' + len + '字符'));
                        }

                        return callback();
                    }
                }],
                requiredEditorContentLength: [{required: true,trigger: 'blur',
                    validator: (rule, value, callback, id) => {
                        let name = '';
                        let len = 500;
                        let idx = rule.field.lastIndexOf('.');
                        let itemId = (idx >= 0) ? rule.field.substring(idx + 1) : rule.field;
                        if (itemId === 'work_7value') {
                            name = '工作内容及业绩';
                            len = 1000;
                        }
                        if (value && !checkEditorContent(value, 500)) {
                            return callback(new Error(name + '不能超过' + len + '字符'));
                        }

                        if (value === null || value === ''){
                            return callback("请输入工作内容及业绩")
                        }

                        return callback();
                    }}
                ],
                contactNumberValidator: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkPhone(value)) {
                            return callback('请输入正确的联系电话');
                        }

                        return callback();
                    }
                }],
            },
        }
    },
    methods: {
        getDictionaryMapByCodeList() {
            this.dict = {};
            let codes = ['resume_label', 'language', 'highest_degree', 'nation', 'job_title',
                'politics_status', 'health_condition', 'marital_status', 'level', 'degree',
                'nature_of_business', 'educational_mode', 'relationship'];
            reqDictionaryMapByCodeList(codes).then((res) => {
                this.dict = res.data.data;
            }).catch(() => {
                this.dict = {};
            })
        },
        handleBeforeUpload(file, maxNum) {
            this.fileCounts++;
            if (this.fileCounts > maxNum) {
                this.$Message.error('最多只能上传' + maxNum + '个文件');
                return false;
            }
        },
        //上传文件大小
        handExceeded(file, maxSize) {
            this.$Message.error('文件大小不得超过' + maxSize + 'M');
        },
        //上传成功
        handleSuccess(res, file, fileList, propName) {
            this.fileCounts = 0;
            if (res.code === 1) {
                let name = res.data.currentName;
                downloadFile({fileName: name, type: 'private'}).then((res2) => {
                    let myData = window.URL.createObjectURL(new Blob([res2.data]));
                    this[propName].push({imgName: name, imgData: myData});
                    this.$Message.success('上传成功');
                });
            } else {
                this.$Message.error('上传失败');
            }
        },
        handlePreview(imgData) {
            this.imgView = imgData;
            this.showPreview = true;
        },
        handleRemove(propName, index) {
            reqDeleteFiles([{fileName: this[propName][index].imgName, type: 'private'}]).then((res) => {
                if (res.data.data && res.data.data.length > 0 && res.data.data[0].result) {
                    this[propName].splice(index, 1);
                } else {
                    this.$Message.error('删除失败');
                }
            })
        },
        handleFileSuccess(res, file, fileList) {
            if (res.code === 1) {
                this.addForm.originalFileName = res.data.orignalName;
                this.addForm.fileName = res.data.currentName;
            } else {
                this.addForm.originalFileName = null;
                this.addForm.fileName = null;
                this.$Message.error('上传失败');
            }
        },
        handleDownload(originalFileName, fileName) {
            let para = {
                type: 'private',
                fileName: fileName
            }
            downloadFile(para).then((res) => {
                if (res.data.size > 0) {
                    let blob = new Blob([res.data])
                    let downloadElement = document.createElement('a')
                    let href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = originalFileName //下载后文件名
                    document.body.appendChild(downloadElement)
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                } else {
                    this.$Message.error('下载失败')
                }
            }).catch(() => {
                this.$Message.error('下载失败')
            })
        },
        handleCountySelected(node, instanceId, propName) {
            this.addForm[propName] = node.full_label;
        },
        handleSelectedChange(obj, propName) {
            this.addForm[propName] = obj?.label;
            if (propName === 'politicsName' && obj.value === 202703) {
                this.addForm.partyDate = null;
            }
        },
        _handleSelectedChange(obj, idx, objName, propName) {
            let label = (undefined === obj) ? null : obj.label;
            this.addForm[objName][idx][propName+'label'] = label;
            if (propName === 'language_1') {
                this.addForm[objName][idx]['language_2value'] = null;
                this.addForm[objName][idx]['language_2label'] = null;
            }
        },
        removeLanguage(idx) {
            this.addForm.language.splice(idx, 1);
        },
        addMoreLanguage() {
            this.addForm.language.push({
                language_1: '语种', language_1value: null, language_1label: null,
                language_2: '掌握程度', language_2value: null, language_2label: null
            });
        },
        removeEducation(idx) {
            this.addForm.education.splice(idx, 1);
        },
        addMoreEducation() {
            this.addForm.education.push({
                education_1: '起止年月', education_1value: null,
                education_2: '毕业学校', education_2value: null,
                education_3: '专业', education_3value: null,
                education_4: '学历', education_4value: null, education_4label: null,
                education_5: '学位', education_5value: null, education_5label: null,
                education_6: '教育方式', education_6value: '统招',
                education_7: '证明人', education_7value: null,
                education_8: null, education_8value: null
            });
        },
        removeWork(idx) {
            this.addForm.work.splice(idx, 1);
        },
        addMoreWork() {
            this.addForm.work.push({
                work_1: '起止年月', work_1value: null,
                work_2: '工作单位', work_2value: null,
                work_3: '单位性质', work_3value: null, work_3label: null,
                work_4: '职务/职位', work_4value: null,
                work_5: '证明人', work_5value: null,
                work_6: '联系电话', work_6value: null,
                work_7: '工作内容及业绩', work_7value: null,
                work_8: null, work_8value: null,
                work_9: '离职原因', work_9value: null
            });
        },
        removeProject(idx) {
            this.addForm.project.splice(idx, 1);
        },
        addMoreProject() {
            this.addForm.project.push({
                project_1: '项目名称', project_1value: null,
                project_2: '项目描述', project_2value: null,
                project_3: '项目职责', project_3value: null,
                project_4: '项目业绩', project_4value: null
            });
        },
        removeFamily(idx) {
            this.addForm.family.splice(idx, 1);
        },
        addMoreFamily() {
            this.addForm.family.push({
                family_1: '姓名', family_1value: null,
                family_2: '出生年份', family_2value: null,
                family_3: '关系', family_3value: null, family_3label: null,
                family_4: '政治面貌', family_4value: null, family_4label: null,
                family_5: '工作单位及职务', family_5value: null,
                family_6: '联系电话', family_6value: null
            });
        },
        handleSubmit() {
            let that = this;
            that.loading = true;
            that.$refs['addForm'].validate((valid) => {
                if (!valid) {
                    setTimeout(function (){
                        document.querySelector('.ivu-form-item-error-tip').scrollIntoView(false)
                    },500)
                    that.loading = false;
                    return false;
                }

                if (that.addForm.jobTitleId && that.addForm.jobTitleId.length > 0) {
                    let items = that.dict.job_title.filter(obj => that.addForm.jobTitleId.includes(obj.id));
                    that.addForm.jobTitleName = items && items.map(item => item.name).join('，');
                } else {
                    that.addForm.jobTitleId = null;
                    that.addForm.jobTitleName = null;
                }
                if (that.addForm.birthday) {
                    that.addForm.birthday = that.$moment(that.addForm.birthday).format('YYYY-MM-DD');
                }
                if (that.addForm.partyDate) {
                    that.addForm.partyDate = that.$moment(that.addForm.partyDate).format('YYYY-MM-DD');
                }
                if (that.addForm.startWorkFrom) {
                    that.addForm.startWorkFrom = that.$moment(that.addForm.startWorkFrom).format('YYYY-MM-DD');
                }
                if (that.addForm.dutyTime) {
                    that.addForm.dutyTime = that.$moment(that.addForm.dutyTime).format('YYYY-MM-DD');
                }

                that.addForm.language && that.addForm.language.map((item, index) => {
                    if (!item.language_1value && !item.language_2value) {
                        that.addForm.language.splice(index, 1)
                    }
                });

                that.addForm.education && that.addForm.education.map((item, index) => {
                    if (!item.education_1value && !item.education_2value && !item.education_3value
                        && !item.education_4value && !item.education_5value && !item.education_7value && !item.education_8value) {
                        that.addForm.education.splice(index, 1);
                    } else {
                        if (item.education_1value) {
                            item.education_1value = that.$moment(item.education_1value).format('YYYY-MM-DD');
                        }
                        if (item.education_8value) {
                            item.education_8value = that.$moment(item.education_8value).format('YYYY-MM-DD');
                        }
                    }
                });

                that.addForm.work && that.addForm.work.map((item, index) => {
                    if (!item.work_1value && !item.work_2value && !item.work_3value && !item.work_4value && !item.work_5value
                        && !item.work_6value && !item.work_7value && !item.work_8value && !item.work_9value) {
                        that.addForm.work.splice(index, 1);
                    } else {
                        if (item.work_1value) {
                            item.work_1value = that.$moment(item.work_1value).format('YYYY-MM-DD');
                        }
                        if (item.work_8value) {
                            item.work_8value = that.$moment(item.work_8value).format('YYYY-MM-DD');
                        }
                    }
                });

                that.addForm.project && that.addForm.project.map((item, index) => {
                    if (!item.project_1value && !item.project_2value && !item.project_3value && !item.project_4value) {
                        that.addForm.project.splice(index, 1);
                    } else if (item.family_2value) {
                        item.family_2value = that.$moment(item.family_2value).format('YYYY');
                    }
                });

                that.addForm.family && that.addForm.family.map((item, index) => {
                    if (!item.family_1value && !item.family_2value && !item.family_3value && !item.family_4value
                        && !item.family_5value && !item.family_6value) {
                        that.addForm.family.splice(index, 1);
                    } else if (item.family_2value) {
                        item.family_2value = that.$moment(item.family_2value).format('YYYY');
                    }
                });
                if (that.photoImgArray && that.photoImgArray.length > 0) {
                    that.addForm.photo = that.photoImgArray[0].imgName;
                }
                that.addForm.certification.idImgs = that.idImgArray?.map(item => item.imgName);
                that.addForm.certification.bareheadedImgs = that.bareheadedImgArray?.map(item => item.imgName);
                that.addForm.certification.degreeImgs = that.degreeImgArray?.map(item => item.imgName);
                that.addForm.certification.educationImgs = that.educationImgArray?.map(item => item.imgName);
                that.addForm.certification.personalImgs = that.personalImgArray?.map(item => item.imgName);
                that.addForm.certification.otherImgs = that.otherImgArray?.map(item => item.imgName);

                if (that.addForm.cityIdArray && that.addForm.cityIdArray.length > 0) {
                    that.addForm.intention = that.addForm.cityIdArray.join(',');
                    let nameArray = [];
                    that.addForm.cityIdArray.map(item => {
                        getLabelById(that.cityOpts, item, nameArray);
                    });
                    that.addForm.intentionName = nameArray.join('，');
                } else {
                    that.addForm.intention = null;
                    that.addForm.intentionName = null;
                }

                reqAddResume(that.addForm).then(res => {
                    that.loading = false;
                    if (res.data.data === 1) {
                        this.$emit('getResume');
                        that.$Message.success('添加成功');
                        this.closeModal(false);
                    } else if (res.data.data === -1) {
                        that.$Message.error('简历重复');
                    } else {
                        that.$Message.error('添加失败');
                    }
                }).catch(() => {
                    this.closeModal(false);
                    that.$Message.error('添加失败');
                })
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.photoImgArray = [];
                this.idImgArray = [];
                this.bareheadedImgArray = [];
                this.degreeImgArray = [];
                this.educationImgArray = [];
                this.personalImgArray = [];
                this.otherImgArray = [];
                this.imgView = null;
                this.fileCounts = 0;
                this.$refs['addForm'].resetFields();
                this.addForm.language = [];
                this.addForm.education = [];
                this.addForm.work = [{
                    work_1: '起止年月', work_1value: null,
                    work_2: '工作单位', work_2value: null,
                    work_3: '单位性质', work_3value: null, work_3label: null,
                    work_4: '职务/职位', work_4value: null,
                    work_5: '证明人', work_5value: null,
                    work_6: '联系电话', work_6value: null,
                    work_7: '工作内容及业绩', work_7value: null,
                    work_8: null, work_8value: null,
                    work_9: '离职原因', work_9value: null
                }];
                this.addForm.project = [];
                this.addForm.family = [];
                this.addForm.certification = {};
                let token = sessionStorage.getItem('Authorization');
                this.header = {Authorization: token};
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
        this.getDictionaryMapByCodeList();
    }
}
</script>
