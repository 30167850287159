<template>
    <Modal v-model="showForm" title='简历推荐' :mask-closable="false">
        <Form label-colon :label-width="90">
            <div class="divider">简历信息</div>
            <FormItem label="姓名">{{ Array.from(data.values()).join('，') }}</FormItem>
            <div class="divider">推荐职位<span class="tips">注：批量推荐只能选择一个职位</span></div>
            <div class="filter">
                <Form ref="filter" label-colon inline>
                    <FormItem>
                        <Input v-model="filter.keywords" placeholder="请输入职位名称或客户名称" clearable/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" @click="getRecommendPositionList">搜索</Button>
                    </FormItem>
                </Form>
            </div>
            <div class="table">
                <div class="table-list">
                    <Table stripe :columns="positionColumns" :data="positionList" :loading="positionLoading"></Table>
                </div>
            </div>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqBatchRecommendResume, reqGetRecommendPositionList} from "../../api/admin-api";

export default {
    name: "batchRecommend",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: '',
    },
    data() {
        return {
            filter:{
                keywords:''
            },
            loading: false,
            positionLoading: false,
            showForm: this.value,
            positionList: [],
            recommendedId: null,
            positionColumns: [
                {
                    title: '职位名称',
                    key: 'name',
                    minWidth: 100,
                },
                {
                    title: '客户名称',
                    key: 'customerName',
                    width: 150,
                },
                {
                    title: '类型',
                    key: 'typeName',
                    width: 100,
                },
                {
                    title: '是否推荐?',
                    key: 'isRecommended',
                    width: 100,
                    render: (h, params) => {
                        return h('i-switch', {
                            props: {value: (this.recommendedId === params.row.id), disabled: (this.recommendedId && this.recommendedId !== params.row.id)},
                            on: {
                                'on-change': (val) => {
                                    if (val) {
                                        this.recommendedId = params.row.id;
                                    } else {
                                        this.recommendedId = null;
                                    }
                                }
                            }
                        }, [
                            h('span', { slot: 'open' }, '是'),
                            h('span', { slot: 'close' }, '否'),
                        ]);
                    },
                    align: 'center'
                },
            ]
        }
    },
    methods: {
        getRecommendPositionList() {
            let that = this;
            that.positionLoading = true;
            reqGetRecommendPositionList({keywords : this.filter.keywords}).then((res) => {
                that.positionLoading = false
                that.positionList = res.data.data;
            }).catch((err) => {
                that.positionList = [];
                that.positionLoading = false;
                console.log("err====", err);
            });
        },
        handleSubmit() {
            this.loading = true;
            if (!this.recommendedId) {
                this.$Message.error('请选择推荐的职位');
                this.loading = false;
                return false;
            }
            let params = {
                resumeIds: Array.from(this.data.keys()),
                positionId: this.recommendedId
            }
            reqBatchRecommendResume(params).then((res) => {
                this.loading = false;
                switch(res.data.data.status) {
                    case -2: {
                        this.$Message.error('简历已推荐到该职位：' + res.data.data.dupResumeIdList.join('，'));
                        break;
                    }
                    case -1: {
                        this.$Message.error('请选择简历或职位');
                        break;
                    }
                    case 0: {
                        this.$Message.error('批量推荐失败');
                        break;
                    }
                    default: {
                        this.$Message.success('推荐成功');
                        this.$emit('getResume');
                        this.$emit('handleUncheck')
                        this.closeModal(false);
                        break;
                    }
                }
            }).catch(() => {
                this.loading = false;
                this.$Message.error('推荐失败');
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.getRecommendPositionList()
            } else {// 反之则关闭页面
                this.recommendedId = null;
                this.closeModal(val);
            }
        }
    }
}
</script>
