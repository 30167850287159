<template>
    <Modal v-model="showForm" :title="modalTitle" :mask-closable="false">
        <Form class="plain-form" ref="markForm" :model="markForm" :rules="markFormRule" label-colon :label-width="90">
            <div class="divider">简历信息</div>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="姓名">{{ markForm.name }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="性别">{{ markForm.gender }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="手机号">{{ markForm.phone }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="邮箱">{{ markForm.email }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="最高学历">{{ markForm.highestDegreeName }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="应聘职位">{{ markForm.applyPosition }}</FormItem>
                </Col>
            </Row>
            <div class="divider">简历状态</div>
            <FormItem class="plain-item-margin" label="标记状态" prop="label">
                <Select v-model="markForm.label" placeholder="请选择" filterable clearable>
                    <Option v-for="item in labelArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqMarkResume} from "@/api/admin-api";
import {reqGetDictionaryListByCode} from "@/api/system-api";

export default {
    name: "markResume",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: '',
    },
    data() {
        return {
            modalTitle: '',
            loading: false,
            showForm: this.value,
            labelArray: [],
            markForm: {
                label: null
            },
            markFormRule: {
                label:[
                    {required: true, message: '请选择状态标记',type: 'integer', trigger: 'change'}
                ]
            },
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['markForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    reqMarkResume(this.markForm).then((res) => {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.$Message.success('标记成功');
                            this.$emit('getResume');
                            this.closeModal(false);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error('标记失败');
                    });
                }
            });
        },
        getLabel() {
            reqGetDictionaryListByCode({code: "resume_label"}).then((res) => {
                this.labelArray = res.data.data
            }).catch(() => {
                this.labelArray = []
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['markForm'].resetFields();
                this.modalTitle = '状态标记 (ID: ' + this.data.id + ')';
                this.markForm = this.data
                this.getLabel()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
